import {mapActions, mapGetters, mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue';

import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
//sections
export default {
  name: "reset",
  mixins: [validationMixin],
  components:{
    mainButton,
  },
  data(){
    return{
      see: false,
      status: false,
      payload: {
        email: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      email: {
        email,
        maxLength: maxLength(30),
        required,
      },
    },
  },
  created() {

  },
  computed:{
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 30))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    ...mapGetters({
      isLoading: 'auth/isLoading'
    }),
  },

  methods:{
    ...mapMutations({
    }),
    ...mapActions({
      reset: `auth/RESET_PASSWORD`
    }),
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        console.log(this.payload);
        this.reset(this.payload).then((result) => {
          this.status = !this.status
        }).catch(error => {
          if (error.status === 400) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}